
import { defineComponent, PropType } from 'vue'
import { Metadata } from '@/data/dms/dmsData'
import { convertPermission } from '@/helpers/util/dmsUtils'

export default defineComponent({
  name: 'MetadataInfo',
  components: {
  },
  props: {
    metadata: {
      type: Array as PropType<Metadata[]>,
    },
    contents: {
      type: Object,
    },
  },
  setup (props) {
    const convertNull = (data: any) => {
      return data || '-'
    }
    const concatAllContentsUri = () => {
      return props.contents?.map((contents: any) => contents.contentsUri).filter((uri: string) => uri)
    }
    const formatMetadataValue = (value: string[], id: string) => {
      if (id === '701') return concatAllContentsUri()
      return value
    }
    return {
      props,
      convertNull,
      convertPermission,
      formatMetadataValue,
    }
  },
})
