
import { defineComponent } from 'vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { postDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'ContentsInfo',
  components: {
    AppButton,
  },
  props: {
    contents: {
      type: Object,
    },
  },
  setup (props) {
    return {
      props,

      async download (data: any) {
        const url = `${BASE_URL}/outer/download/contents`
        const body = JSON.stringify({
          pid: data.pid,
          contentsBundleId: data.contentsBundleId,
          contentsId: data.contentsId,
          fileKbn: 1,
        })
        const response = await postDataCredential(url, body, true)
        if (response.status === 200) {
          const blob = await window
            .fetch('data:;base64,' + response.data.fileValue)
            .then(res => res.blob())
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = response.data.fileName
          link.click()
        }
      },
    }
  },
})
