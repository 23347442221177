
import { defineComponent, PropType } from 'vue'
import { ItemDetail } from '@/data/dms/dmsData'
import PlaceholderImage from '@/components/atoms/PlaceholderImage.vue'

export default defineComponent({
  name: 'ItemInfo',
  components: {
    PlaceholderImage,
  },
  props: {
    item: {
      type: Object as PropType<ItemDetail>,
    },
  },
  setup (props) {
    return {
      props,
    }
  },
})
