
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import OuterItem from '@/components/organisms/dms/OuterItem/OuterItem.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
export default defineComponent({
  components: {
    OuterItem,
    DssIcon,
  },
  setup () {
    const router = useRouter()
    return {
      router,
    }
  },
})
