
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import ItemInfo from '@/components/organisms/dms/OuterItem/ItemInfo.vue'
import ContentsInfo from '@/components/organisms/dms/OuterItem/ContentsInfo.vue'
import MetadataInfo from '@/components/organisms/dms/OuterItem/MetadataInfo.vue'
import { Metadata, ItemDetail } from '@/data/dms/dmsData'
import { getDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

const defaultMetadata = {
  description: '',
  id: '',
  maximum: '',
  minimum: '',
  radioButton: {},
  screenItemName: '',
  sortNo: 0,
  value: [''],
}

export default defineComponent({
  name: 'OuterItem',
  components: {
    ItemInfo,
    ContentsInfo,
    MetadataInfo,
  },
  props: {
  },
  setup () {
    const route = useRoute()
    const pidNum = ref<any>(route.params.pid)
    const pid = ref('info:ndljp/pid/' + pidNum.value)
    const item = ref<ItemDetail>({
      pid: '',
      title: defaultMetadata,
      author: defaultMetadata,
      thumbnailValue: '',
    })
    const contents = ref([])
    const metadata = ref<Metadata[]>([])

    const getItemDetail = async () => {
      const url = `${BASE_URL}/outer/item/sent/detail?pid=${pid.value}`
      const response = await getDataCredential(url)

      item.value = {
        pid: response.data.pid,
        title: response.data.title,
        author: response.data.author,
        thumbnailValue: response.data.thumbnailValue,
      }
      contents.value = response.data.outerContentsList
      metadata.value = response.data.metadataOuterList
    }
    getItemDetail()
    return {
      item,
      contents,
      metadata,
    }
  },
})
